import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { resetPassword } from "../../services/authService";
import AppLogo from "../../components/AppLogo";
import { signOut } from "../../redux/actions/authActions";

import {
  Button,
  Box,
  Card as MuiCard,
  CardContent,
  Paper,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import HelpIcon from "@material-ui/icons/Help";
import { useSnackbar } from "notistack";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  background-color: #f9f8fd;
`;

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 1px 5px 2px rgba(0, 0, 0, .2)",
    padding: "1rem",
  },
  resetButton: {
    color: "white",
    backgroundColor: "#1D88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "red",
    transform: "scale(.8)",
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const IconWithTooltip = () => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      placement="right-start"
      title="Your password must be at least 6 characters and contain at least one lowercase letter, one uppercase letter,one special character(punctuation,etc) and one number."
    >
      <HelpIcon className={classes.icon} />
    </Tooltip>
  );
};

function ResetPassword() {
  const history = useHistory();
  const [responseMessage, setResponseMessage] = useState("");

  const query = useQuery();
  const email = query.get("email");
  const token = query.get("token").replaceAll(" ", "+");

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <AppLogo mb="20px" />
      <Card className={classes.card}>
        <CardContent>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Box className={classes.box}>
            <span> Password requirements:</span>
            <IconWithTooltip />
          </Box>

          <Formik
            initialValues={{
              password: "",
              passwordConfirmation: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6)
                .max(255)
                .required("Password is required"),
              passwordConfirmation: Yup.string()
                .min(6)
                .max(255)
                .required("Confirm Password is required")
                .test(
                  "passwords-match",
                  "Passwords must match",
                  function (value) {
                    return this.parent.password === value;
                  }
                ),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const response = await resetPassword({
                  email: email,
                  password: values.password,
                  confirmPassword: values.passwordConfirmation,
                  token: token,
                });

                if (response.isSuccess) {
                  dispatch(signOut());
                  localStorage.removeItem("token");
                  history.push("/");
                  enqueueSnackbar("Your password has been reset.", {
                    variant: "success",
                    autoHideDuration: 3000,
                  });
                } else {
                  setResponseMessage(response.message);
                }
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {responseMessage === "Not Valid" && (
                  <Alert mt={2} mb={1} severity="warning">
                    Please enter a valid password
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={email}
                  fullWidth
                  mt={4}
                  disabled
                />

                <TextField
                  InputLabelProps={{ style: { pointerEvents: "auto" } }}
                  type="password"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />

                <TextField
                  type="password"
                  name="passwordConfirmation"
                  label="Confirm Password"
                  value={values.passwordConfirmation}
                  error={Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation
                  )}
                  fullWidth
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isSubmitting}
                  className={classes.resetButton}
                >
                  Reset password
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default ResetPassword;
